<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
    <ayl-main title="人员信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" v-for="(item, idx) in basicTitle" :key="idx">
          <span>{{ item.lable }}：</span>
          <span>{{ basicTitleData[item.value] || '- -' }}</span>
        </div>
      </div>
    </ayl-main>
    <ayl-main title="报警信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" v-for="(item, idx) in otherTitle" :key="idx" v-show="idx < (otherTitle.length-1)">
            <span>{{ item.lable }}：</span>
            <span>{{ otherTitleData[item.value] || '- -' }}</span>
        </div>
        <div class="content content-map">
            <span>位置：</span>
            <span v-text="otherTitleData.postion"></span>
            <a class="mapView" @click="centerDialogVisible = true">查看地图</a>
            <el-dialog
            :visible.sync="centerDialogVisible"
            width="50%"
            @open="newInitAmap"
            center>
            <span>
              <div id="container" style="width: 100%; height: 600px"></div>
            </span>
          </el-dialog>
        </div>
      </div>
    </ayl-main>

    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="onClose">关闭</el-button>
    </div>
    </div>

  </div>
</template>

<script>
  export default {
    data () {
      return {
        centerDialogVisible: false,
        processDialog: false,
        dialogFormVisible: false,
        formLabelWidth: '120px',
        map: null,
        nav: [
          {name: "人员报警详情", path: ""},
        ],
        basicTitle:[
          {
            lable:'姓名',
            value:'driverName'
          },
          {
            lable:'设备号',
            value:'deviceId'
          },
          {
            lable:'SIM卡号',
            value:'simCardNumber'
          },
          {
            lable:'公司',
            value:'company'
          },
          {
            lable:'所属标段',
            value:'bidName'
          },
          {
            lable:'所属网格',
            value:'gridName'
          },
          {
            lable:'身份证号',
            value:'driverIdCardNumber'
          },
          {
            lable:'手机号码',
            value:'driverContactPhone'
          },
        ],
        basicTitleData: {
          driverName: null,
          deviceId: null,
          simCardNumber: null,
          company: null,
          bidName: null,
          gridName: null,
          driverIdCardNumber: null,
          driverContactPhone: null,
        },
        otherTitle: [
          {
            lable:'GPS状态',
            value:'gpsStatus'
          },
          {
            lable:'电量',
            value:'wristbandBattery'
          },
          {
            lable:'是否在标段内',
            value:'inBidDes'
          },
          {
            lable:'是否在网格内',
            value:'inGridDes'
          },
          {
            lable:'速度',
            value:'speed'
          },
          {
            lable:'经度',
            value:'longitude'
          },
          {
            lable:'纬度',
            value:'latitude'
          },
          {
            lable:'报警类型',
            value:'alarmIdentifier'
          },
          {
            lable:'报警等级',
            value:'alarmType'
          },
          {
            lable:'报警开始时间',
            value:'alarmStartTime'
          },
          {
            lable:'报警结束时间',
            value:'alarmEndTime'
          },
        ],
        otherTitleData:{
          gpsStatus: null,
          wristbandBattery: null,
          inBidDes: null,
          inGridDes: null,
          speed: null,
          longitude: null,
          latitude: null,
          alarmIdentifier: null,
          alarmType: null,
          alarmStartTime: null,
          alarmEndTime: null,
          attache: null,
          attacheKey: null,
          tenantId: null,
        },


        submitLoading: false,
      }
    },
    methods:{
      initAmap (){
        // 构造点标记
        var marker = new AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: [this.otherTitleData.longitude,this.otherTitleData.latitude]
        });
        // 构造矢量圆形
        var circle = new AMap.Circle({
            center:[this.otherTitleData.longitude,this.otherTitleData.latitude], // 圆心位置
            radius: 30,  //半径
            strokeColor: "#b1d0f2",  //线颜色
            strokeOpacity: 1,  //线透明度
            strokeWeight: 1,  //线粗细度
            fillColor: "#d7e4f0",  //填充颜色
            fillOpacity: 0.8 //填充透明度
        });
        //初始化地图
        this.map= new AMap.Map('container', {
          center:[this.otherTitleData.longitude,this.otherTitleData.latitude],
          resizeEnable: true,
          zoom: 10,
        });
        this.map.add([marker,circle]);
      },
      newInitAmap() {
        this.$nextTick(() => {
            this.initAmap()
        });
      },


      onDialog() {
        return this.processDialog = true
      },
      
      onClose() {
        BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    },
    async mounted () {
      // await this.$search(this.table)
      let alarmData= await this.$api_hw.alarmManage_personAlarmDetail({
        alarmName: this.$route.query.alarmName,
        alarmType: this.$route.query.alarmType,
        deviceId: this.$route.query.deviceId,
        alarmStartTime: this.$route.query.alarmStartTime,
        tenantId: this.$route.query.tenantId
      })
      this.basicTitleData.driverName = alarmData.hasOwnProperty('driverName') ? alarmData.driverName : null
      this.basicTitleData.deviceId = alarmData.hasOwnProperty('deviceId') ? alarmData.deviceId : null
      this.basicTitleData.simCardNumber = alarmData.hasOwnProperty('simCardNumber') ? alarmData.simCardNumber : null
      this.basicTitleData.company = alarmData.hasOwnProperty('company') ? alarmData.company : null
      this.basicTitleData.bidName = alarmData.hasOwnProperty('bidName') ? alarmData.bidName : null
      this.basicTitleData.gridName = alarmData.hasOwnProperty('gridName') ? alarmData.gridName : null
      this.basicTitleData.driverIdCardNumber = alarmData.hasOwnProperty('driverIdCardNumber') ? alarmData.driverIdCardNumber : null
      this.basicTitleData.driverContactPhone = alarmData.hasOwnProperty('driverContactPhone') ? alarmData.driverContactPhone : null

      this.otherTitleData.gpsStatus = alarmData.hasOwnProperty('gpsStatus') ? alarmData.gpsStatus : null
      this.otherTitleData.wristbandBattery = alarmData.hasOwnProperty('wristbandBattery') ? alarmData.wristbandBattery : null
      this.otherTitleData.inBidDes = alarmData.hasOwnProperty('inBidDes') ? alarmData.inBidDes : null
      this.otherTitleData.inGridDes = alarmData.hasOwnProperty('inGridDes') ? alarmData.inGridDes : null
      this.otherTitleData.speed = alarmData.hasOwnProperty('speed') ? alarmData.speed : null
      this.otherTitleData.longitude = alarmData.hasOwnProperty('position') ? alarmData.position.longitude : null
      this.otherTitleData.latitude = alarmData.hasOwnProperty('position') ? alarmData.position.latitude : null
      this.otherTitleData.postion = alarmData.hasOwnProperty('location') ? alarmData.location : null
      this.otherTitleData.alarmIdentifier = alarmData.hasOwnProperty('alarmIdentifier') ? alarmData.alarmIdentifier : null
      this.otherTitleData.alarmType = alarmData.hasOwnProperty('alarmType') ? alarmData.alarmType : null
      this.otherTitleData.alarmStartTime = alarmData.hasOwnProperty('alarmStartTime') ? this.$dayjs(alarmData.alarmStartTime).format('YYYY-MM-DD HH:mm:ss') : null
      this.otherTitleData.alarmEndTime = alarmData.hasOwnProperty('alarmEndTime') ? this.$dayjs(alarmData.alarmEndTime).format('YYYY-MM-DD HH:mm:ss') : null
      this.otherTitleData.attache = alarmData.attache
      this.otherTitleData.attacheKey = alarmData.attacheKey
      this.otherTitleData.tenantId = alarmData.tenantId

    }
  }
</script>
<style lang='sass' scoped>
.clearBox
  .content
    .mapView
      color: #0575E6
      margin-left: 14px
      cursor: pointer

  .content-map
    width: 600px
.content
    display: inline-block
    width: 320px
    margin-bottom: 36px

/deep/ .el-dialog__header
    padding-bottom: 30px
.content-box /deep/ .el-form-item
    width: 100%
.attachment-main
    width: 100%
    .right-img
      width: 20%
      height: 520px
      display: inline-block
      vertical-align: top
      margin-left: 2%
      overflow-y: auto
      .images
        width: 100%
    .left-img
      width: 78%
      height: 520px
      display: inline-block
      .images
        width: 100%
        height: 100%
    .class
      display: block
    .show
      display: none

</style>
